import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import {
  LangChangeEvent,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ConfirmationService, MenuItem, SharedModule } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription } from 'rxjs';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/app/core/common/helpers/LocalStorageUtils';
import { throttle } from 'src/app/core/common/helpers/ThrottleUtil';
import { CompaniesState } from 'src/app/core/common/models/company-reducer/CompaniesState';
import { ProfileCompanyState } from 'src/app/core/common/models/company-reducer/ProfileCompanyState';
import { MenuItemModel } from 'src/app/core/common/models/menu/MenuItemModel';
import { CompanyDataState } from 'src/app/core/common/models/navbar/CompanyDataState';
import { NotificationService } from 'src/app/core/common/notification/notification.service';
import { AuthService } from 'src/app/core/common/services/auth/auth.service';
import { CompanyServiceApi } from 'src/app/core/common/services/company/company.api.service';
import { MenuService } from 'src/app/core/common/services/menu/menu.service';
import {
  removeComapaniesDetails,
  updateSelectedCompany,
} from 'src/app/core/common/store/company.actions';
import { IMenuState } from 'src/app/core/common/store/menu.state';
import { TablePreferencesState } from 'src/app/core/document-emitter/store/table-preferences.state';
import { ProfileFacade } from 'src/app/pages/settings/general-settings/management-registers/my-profile/facade/ProfileFacade';
import { environment } from 'src/environments/environment';
import { LoadNavbarComponent } from '../../load/load-navbar/load-navbar.component';
import { AppModalPasswordComponent } from '../../modals/app-modal-password/app-modal-password.component';

/**
 * * Componente de navbar
 * @description Estão disponíveis as seguintes ações:
 * - Ir para home (através da logo do Propulsor)
 * - Pesquisar e acessar telas (através do campo de pesquisa)
 * - Acessar perfil (através do botão de perfil)
 * - Alterar a empresa contexto [domínio] (através do botão de empresa) -> é a empresa para a qual serão gerados os documentos no sistema
 * - Acessar notificações (através do botão de notificação)
 * - Acessar configurações (através do botão de configurações)
 * - Sair do sistema (através do botão de logout)
 *
 * @author Dariane Abich
 * @date 24/04/2024
 * @export
 * @class AppNavbarComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    TooltipModule,
    CommonModule,
    LoadNavbarComponent,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    SharedModule,
    ButtonModule,
    MenuModule,
    NgSelectModule,
    ConfirmPopupModule,
    TranslateModule,
  ],
})
export class AppNavbarComponent implements OnInit, OnDestroy {
  private router: Router = inject(Router);
  private formBuilder: UntypedFormBuilder = inject(UntypedFormBuilder);
  private authservice: AuthService = inject(AuthService);
  private menuService: MenuService = inject(MenuService);
  private profileFacade: ProfileFacade = inject(ProfileFacade);
  private notification: NotificationService = inject(NotificationService);
  private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private translate: TranslateService = inject(TranslateService);
  private companyService: CompanyServiceApi = inject(CompanyServiceApi);
  private confirmation: ConfirmationService = inject(ConfirmationService);
  private dialogService: DialogService = inject(DialogService);
  private store: Store<{
    menu: IMenuState;
    profileCompany: ProfileCompanyState;
    tablePreferences: TablePreferencesState;
  }> = inject(
    Store<{
      menu: IMenuState;
      profileCompany: ProfileCompanyState;
      tablePreferences: TablePreferencesState;
    }>
  );

  readonly SELECTCOMPNAV_KEY = 'select_Company_nav_propulsor';

  testeForm!: UntypedFormGroup;
  searchMenuForm!: UntypedFormGroup;

  menusDisponiveis: MenuItemModel[] = [];

  savingPassword: boolean = false;
  showMessage: boolean = true;
  loadingNavbar: boolean = true;

  nameUser!: string | undefined;

  @ViewChild('searchAllMenus') autocomplete!: ElementRef;

  companyData!: CompanyDataState;

  private subscription?: Subscription;

  userItems: MenuItem[] = [];
  settingsItems: MenuItem[] = [];
  notificationsItems: MenuItem[] = [];

  isDevMode: boolean = false;

  constructor() {
    this.subscription = this.companyService.isCompanyChanged$.subscribe(
      (isCompanyChanged) => {
        if (isCompanyChanged) {
          this.initProfileCompany();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.initNavbar();
    this.searchMenuForm = this.formBuilder.group({
      searchAllMenus: [''],
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadMenuItems();
    });

    if (environment.production) this.isDevMode = false;
    else this.isDevMode = true;
  }

  /**
   * @description Inicializar perfil
   */
  initProfileCompany() {
    this.store
      .select((state) => state.profileCompany)
      .subscribe((profileCompany) =>
        this.checkIfUserHasMoreThanOneCompanies(profileCompany)
      );
  }

  /**
   * @description Pesquisar o item de menu (tela) entre os menus disponíveis para o usuário
   * @param event - valor do campo de pesquisa
   */
  @throttle(500)
  searchMenu(event: any): void {
    this.showMessage = true;
    this.menuService.searchMenuQuery(event.query).then((data) => {
      if (data.length !== 0) this.showMessage = false;
      this.menusDisponiveis = data;
    });
  }

  /**
   * @description Liberar a pesquisa de todos os menus
   */
  clickDivSearchMenu() {
    this.searchMenuForm.get('searchAllMenus')?.enable();
  }

  /**
   * @description Verificar se o usuário tem mais de uma empresa relacionada
   * @param profile ProfileState - empresas
   * @returns
   */
  checkIfUserHasMoreThanOneCompanies(profile: ProfileCompanyState) {
    this.companyData = CompanyDataState(false, false, '', null, null, [], []);

    if (profile?.defaultCompany) {
      this.companyData.hasDefaultCompany = true;
      this.companyData.defaultCompanyId = profile?.defaultCompany?.id!;
      this.companyData.defaultCompanyName = profile?.defaultCompany?.name!;

      let companyNavSelectLocalStorage = getLocalStorageItem(
        this.SELECTCOMPNAV_KEY
      );

      if (companyNavSelectLocalStorage) {
        this.companyData.selectedCompany =
          companyNavSelectLocalStorage as CompaniesState;
      } else {
        this.companyData.selectedCompany =
          profile?.selectedCompany as CompaniesState;
      }

      if (profile?.allCompanies) {
        profile?.allCompanies?.forEach((company) => {
          this.companyData.allCompaniesList?.push(company);
        });
      }

      if (profile?.othersCompanies) {
        if (profile?.othersCompanies?.length! > 0) {
          this.companyData.hasOthersCompanies = true;

          profile?.othersCompanies?.forEach((company) => {
            this.companyData.otherCompaniesList?.push(company);
          });
        }
      }
    }

    setTimeout(() => {
      this.loadingNavbar = false;
    }, 600);

    return this.companyData;
  }

  /**
   * @description Atualizar a empresa selecionada
   * @param event - valor selecionado
   */
  changeSelectedCompany(event: any) {
    setLocalStorageItem(this.SELECTCOMPNAV_KEY, event);

    this.store.dispatch(updateSelectedCompany({ selectedCompany: event }));
    this.initProfileCompany();
  }

  /**
   * @description Redirecionar para o formulário de cadastro de minhas empresas
   */
  goToNewCompany() {
    this.router.navigate([
      '/settings/general-settings/management-registers/my-companies/form',
    ]);
  }

  /**
   * @description Redirecionar para o menu selecionado
   * @param menuModel MenuItemModel
   */
  clickSearchMenu(menuModel: any) {
    if (menuModel?.value.url) {
      this.router.navigate([menuModel?.value.url]);
    } else {
      this.notification.menuNotAvailable();
    }

    this.searchMenuForm.reset();
    this.searchMenuForm.get('searchAllMenus')?.disable();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * @description Carrega as opções de ações do usuário no navbar
   */
  loadMenuItems() {
    this.settingsItems = [
      {
        label: this.translate.instant('components.generalConfigurations'),
        icon: 'pi pi-cog',
        command: () => {
          this.router.navigate(['settings/general-settings']);
        },
      },
      {
        label: this.translate.instant('components.planAndBilling'),
        icon: 'pi pi-credit-card',
        command: () => {},
        disabled: true,
      },
      {
        label: this.translate.instant('components.aboutPropulsor'),
        icon: 'pi pi-info-circle',
        command: () => {
          this.router.navigate(['settings/about']);
        },
      },
    ];

    this.userItems = [
      {
        label: this.translate.instant('common.myProfile'),
        icon: 'pi pi-user-edit',
        command: () => {
          this.router.navigate([
            '/settings/general-settings/management-registers/my-profile',
          ]);
        },
      },
      {
        label: this.translate.instant('components.changePassword'),
        icon: 'pi pi-lock',
        command: () => {
          this.openPasswordChangeModal();
        },
      },
    ];

    this.notificationsItems = [
      {
        label: this.translate.instant('components.noNotifications'),
        disabled: true,
      },
    ];
  }

  /**
   * @description Inicializar o navbar
   */
  initNavbar() {
    this.testeForm = this.formBuilder.group({
      selectedCity: [''],
    });

    this.store
      .select((state) => state.menu.username)
      .subscribe((username) => {
        this.nameUser = username;
      });

    this.initProfileCompany();

    this.loadMenuItems();
  }

  /**
   * @description Ação de sair do sistema (logout)
   */
  logout() {
    this.authservice.signOut();
    this.store.dispatch(removeComapaniesDetails());
  }

  /**
   * @description Redefinir a senha do usuário logado
   */
  resetPassword(passwordValue: any) {
    this.savingPassword = true;

    this.profileFacade.changePassword(passwordValue).subscribe({
      next: (_) => {
        this.notification.changePasswordSuccess();
        this.savingPassword = false;
      },
      error: (_) => {
        this.savingPassword = false;
      },
    });
  }

  /**
   * @description Chama o popup de confirmação do logout do usuário
   */
  confirmLogout(event: Event) {
    this.confirmation.confirm({
      key: 'myConfirmationDialog',
      target: event.target as EventTarget,
      message: this.translate.instant('messages.areYouSureWantQuit'),
      acceptLabel: this.translate.instant('enums.primeNgConfig.accept'),
      acceptButtonStyleClass: 'p-button-raised primary',
      rejectLabel: this.translate.instant('enums.primeNgConfig.reject'),
      rejectButtonStyleClass: 'p-button-outlined primary mr-2',
      accept: () => {
        this.logout();
      },
    });
  }

  /**
   *@description Abrir o modal para alterar a senha
   */
  openPasswordChangeModal() {
    const ref = this.dialogService.open(AppModalPasswordComponent, {
      header: this.translate.instant('common.toResetPassword'),
      width: '512px',
      data: {
        hasOldPassword: true,
      },
    });

    ref.onClose.subscribe((response) => {
      if (response?.success) {
        let formPassword = response?.data;
        this.resetPassword(formPassword);
      }
    });
  }
}
