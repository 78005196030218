import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PasswordModule } from 'primeng/password';
import { TooltipModule } from 'primeng/tooltip';
import { ModalComponentService } from 'src/app/core/common/services/modal/modal-component.service';
import {
  matchValidator,
  PasswordValidators,
} from 'src/app/core/common/validators/PasswordValidator';
import {} from 'stream';

@Component({
  selector: 'app-modal-password',
  templateUrl: './app-modal-password.component.html',
  styleUrls: ['./app-modal-password.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PasswordModule,
    SharedModule,
    DividerModule,
    CommonModule,
    ButtonModule,
    TooltipModule,
    TranslateModule,
  ],
})
export class AppModalPasswordComponent implements OnInit {
  private fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private ref: DynamicDialogRef = inject(DynamicDialogRef);
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private mcService: ModalComponentService = inject(ModalComponentService);
  private elementRef: ElementRef = inject(ElementRef);

  hasOldPassword: boolean = false;
  modalpasswordChange: boolean = true;
  savingPassword: boolean = false;

  passwordForm!: UntypedFormGroup;

  strongRegex =
    '(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[$@^!%*?&#{}()/\\|:;<>,."\'~`_+=-]).{8,}';

  showErrorMessageNewPassword: boolean = false;
  showErrorMessageConfirmNewPassword: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.hasOldPassword = this.config.data.hasOldPassword;
    this.initForm();

    this.mcService.setFocus('newPassword', this.elementRef);
  }

  initForm() {
    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          PasswordValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          PasswordValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          PasswordValidators.patternValidator(
            new RegExp('(?=.*[$@^!%*?&#{}()/\\|:;<>,."\'~`_+=-])'),
            {
              requiresSpecialChars: true,
            }
          ),
          matchValidator('confirmNewPassword', true),
        ]),
      ],
      confirmNewPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          matchValidator('newPassword'),
        ]),
      ],
    });

    if (!this.hasOldPassword) {
      this.passwordForm
        .get('currentPassword')
        ?.removeValidators(Validators.required);
      this.passwordForm.get('currentPassword')?.updateValueAndValidity();
    }
  }

  changePasswordClick() {
    this.ref.close({ success: true, data: this.passwordForm.value });
  }

  /**
   * @description Evento de clique do botão de fechamento
   */
  onClose() {
    this.ref.close({ success: false, data: undefined });
  }

  /**
   * @description Verificar se o campo de senha e de nova senha está vazio e se forem diferentes, seta o erro de matching
   * @param event
   */
  verifyNewPassword(psswrd: any, confirmPasswrd: any) {
    if (psswrd.value.length > 0) this.showErrorMessageNewPassword = false;
    else this.showErrorMessageNewPassword = true;

    if (confirmPasswrd.value.length > 0)
      this.showErrorMessageConfirmNewPassword = false;
    else this.showErrorMessageConfirmNewPassword = true;

    if (psswrd.value !== confirmPasswrd.value) {
      this.passwordForm.controls['confirmNewPassword'].setErrors({
        matching: true,
      });
    }

    this.passwordForm.markAllAsTouched();
  }

  // convenience getter for easy access to form controls
  get f() {
    return this.passwordForm.controls;
  }

  get passwordValid() {
    return this.passwordForm.controls['newPassword'].errors === null;
  }

  get requiredValid() {
    return !this.passwordForm.controls['newPassword'].hasError('required');
  }

  get minLengthValid() {
    return !this.passwordForm.controls['newPassword'].hasError('minlength');
  }

  get requiresDigitValid() {
    return !this.passwordForm.controls['newPassword'].hasError('requiresDigit');
  }

  get requiresUppercaseValid() {
    return !this.passwordForm.controls['newPassword'].hasError(
      'requiresUppercase'
    );
  }

  get requiresLowercaseValid() {
    return !this.passwordForm.controls['newPassword'].hasError(
      'requiresLowercase'
    );
  }

  get requiresSpecialCharsValid() {
    return !this.passwordForm.controls['newPassword'].hasError(
      'requiresSpecialChars'
    );
  }
}
