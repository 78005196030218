// This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
  production: false,

  urlContatoSuporte: 'https://d3hpa6b1byxfpz.cloudfront.net/contact',
  urlPoliticaPrivacidade:
    'https://d3hpa6b1byxfpz.cloudfront.net/privacy-policy',
  urlCadastro: 'https://d3hpa6b1byxfpz.cloudfront.net/',
  phoneCompany: '+55 55 3431 9015',
  whatsappLinkCompany: 'https://api.whatsapp.com/send?phone=5555991633574',
  whatsappNumberCompany: '+55 55 99163 3574',

  apiUrl: 'https://api-dev.propulsor.net.br/',

  cognito: {
    userPoolId: 'sa-east-1_T4EAw6w1j',
    userPoolWebClientId: '8e6k88ct57il9b1s5cr6n47ho',
  },

  secretKeyCrypto: 'PropulsorSecretKeyCrypto2022202316141114',

  tablePreferencesVersion: 8,

  appVersion: '1.76',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
